// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-rsvp-form-js": () => import("/Users/helioha/websites/kellio-wedding/src/templates/rsvp-form.js" /* webpackChunkName: "component---src-templates-rsvp-form-js" */),
  "component---src-templates-rsvp-form-card-js": () => import("/Users/helioha/websites/kellio-wedding/src/templates/rsvp-form-card.js" /* webpackChunkName: "component---src-templates-rsvp-form-card-js" */),
  "component---src-pages-404-js": () => import("/Users/helioha/websites/kellio-wedding/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-get-invitation-js": () => import("/Users/helioha/websites/kellio-wedding/src/pages/get-invitation.js" /* webpackChunkName: "component---src-pages-get-invitation-js" */),
  "component---src-pages-index-js": () => import("/Users/helioha/websites/kellio-wedding/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-story-js": () => import("/Users/helioha/websites/kellio-wedding/src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-pt-get-invitation-js": () => import("/Users/helioha/websites/kellio-wedding/src/pages/pt/get-invitation.js" /* webpackChunkName: "component---src-pages-pt-get-invitation-js" */),
  "component---src-pages-pt-home-js": () => import("/Users/helioha/websites/kellio-wedding/src/pages/pt/home.js" /* webpackChunkName: "component---src-pages-pt-home-js" */),
  "component---src-pages-pt-our-story-js": () => import("/Users/helioha/websites/kellio-wedding/src/pages/pt/our-story.js" /* webpackChunkName: "component---src-pages-pt-our-story-js" */),
  "component---src-pages-pt-registry-js": () => import("/Users/helioha/websites/kellio-wedding/src/pages/pt/registry.js" /* webpackChunkName: "component---src-pages-pt-registry-js" */),
  "component---src-pages-pt-rsvp-code-js": () => import("/Users/helioha/websites/kellio-wedding/src/pages/pt/rsvp-code.js" /* webpackChunkName: "component---src-pages-pt-rsvp-code-js" */),
  "component---src-pages-pt-rsvp-name-js": () => import("/Users/helioha/websites/kellio-wedding/src/pages/pt/rsvp-name.js" /* webpackChunkName: "component---src-pages-pt-rsvp-name-js" */),
  "component---src-pages-pt-rsvp-js": () => import("/Users/helioha/websites/kellio-wedding/src/pages/pt/rsvp.js" /* webpackChunkName: "component---src-pages-pt-rsvp-js" */),
  "component---src-pages-pt-travel-js": () => import("/Users/helioha/websites/kellio-wedding/src/pages/pt/travel.js" /* webpackChunkName: "component---src-pages-pt-travel-js" */),
  "component---src-pages-pt-wedding-js": () => import("/Users/helioha/websites/kellio-wedding/src/pages/pt/wedding.js" /* webpackChunkName: "component---src-pages-pt-wedding-js" */),
  "component---src-pages-registry-js": () => import("/Users/helioha/websites/kellio-wedding/src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-rsvp-code-js": () => import("/Users/helioha/websites/kellio-wedding/src/pages/rsvp-code.js" /* webpackChunkName: "component---src-pages-rsvp-code-js" */),
  "component---src-pages-rsvp-name-js": () => import("/Users/helioha/websites/kellio-wedding/src/pages/rsvp-name.js" /* webpackChunkName: "component---src-pages-rsvp-name-js" */),
  "component---src-pages-rsvp-js": () => import("/Users/helioha/websites/kellio-wedding/src/pages/rsvp.js" /* webpackChunkName: "component---src-pages-rsvp-js" */),
  "component---src-pages-travel-js": () => import("/Users/helioha/websites/kellio-wedding/src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-pages-wedding-js": () => import("/Users/helioha/websites/kellio-wedding/src/pages/wedding.js" /* webpackChunkName: "component---src-pages-wedding-js" */)
}

