module.exports = [{
      plugin: require('/Users/helioha/websites/kellio-wedding/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/helioha/websites/kellio-wedding/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-144819344-1"},
    },{
      plugin: require('/Users/helioha/websites/kellio-wedding/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
